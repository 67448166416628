import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import {
  ExternalServicesState,
  GetCmsFeedFailureProps,
  GetCmsFeedProps,
  GetCmsFeedSuccessProps,
  GetExternalServiceParams,
  GetLayoutSuccessProps,
  FailurePayload,
  GetOneServiceGroupParams,
  GetServiceFeaturesSuccessProps,
  GetServiceGroupsSuccessProps,
  ServiceGroupByIdState,
  ServiceGroupV2,
  ExtendedExternalService,
} from 'APP/Store/Content/types'
import Config from 'APP/Config'
import { TabScreens } from 'APP/Lib/ContentHelpers'
import appLayout from 'APP/Config/cmsFallback/layout.json'

const initialState = {
  layout: {
    loading: false,
    data: {
      ...appLayout,
    },
  },
  serviceFeatures: {
    loading: false,
    data: [],
  },
  serviceCategories: [],
  healthIssueTypeIcons: {},
  cmsFeed: {
    loading: false,
    processing: false,
    data: {},
    error: false,
  },
  services: [],
  serviceGroups: {
    loading: false,
    data: [],
    byId: {} as Record<string, ServiceGroupByIdState>,
  },
  externalServices: {} as Record<string, ExternalServicesState>,
}
const { actions, reducer } = createSlice({
  name: 'content',
  initialState,
  reducers: {
    getLayout: (state) => {
      state.layout.loading = true
    },
    getLayoutSuccess: (state, action: PayloadAction<GetLayoutSuccessProps>) => {
      let screens = action.payload.layout?.screens || []
      if (Config.BRAND_ID == 'sunlifeus') {
        screens = screens.map((screen) => {
          if (screen.mobile_scene_key === 'chatTab') {
            const tabs = screen.tabs?.filter((el) => el.name !== TabScreens.chat.carePlans) || []
            return { ...screen, tabs }
          }
          return screen
        })
      }

      const serviceCategories =
        screens.find((screen) => screen.name === 'Chat')?.tabs?.[0]?.component?.data?.items ?? []

      const reducer = (accumulator, item) => {
        accumulator[item.placeholder_health_issue_type_id] = item.icon_name
        return accumulator
      }
      const healthIssueTypeIcons = serviceCategories.reduce(reducer, {})

      state.layout.loading = false
      state.layout.data = { ...action.payload.layout, screens }
      state.serviceCategories = serviceCategories
      state.healthIssueTypeIcons = healthIssueTypeIcons
    },
    getLayoutFailure: (state) => {
      state.layout.loading = false
      state.services = []
    },
    getServiceFeatures: (state) => {
      state.serviceFeatures = {
        ...(state.serviceFeatures || initialState.serviceFeatures),
        loading: true,
      }
    },
    getServiceFeaturesSuccess: (state, action: PayloadAction<GetServiceFeaturesSuccessProps>) => {
      state.serviceFeatures.loading = false
      state.serviceFeatures.data = action.payload.serviceFeatures?.items || []
    },
    getServiceFeaturesFailure: (state) => {
      state.serviceFeatures.loading = false
    },
    clearCmsFeed: (state) => {
      state.cmsFeed = initialState.cmsFeed
    },
    getCmsFeed: (state, action: PayloadAction<GetCmsFeedProps>) => {
      const { contentId } = action.payload
      // TODO: added initialState.cmsFeed as fallback to avoid weird behaviour described in [DIA-66607]
      const { data } = state.cmsFeed || initialState.cmsFeed
      state.cmsFeed = {
        loading: !data[contentId],
        processing: true,
        data,
        error: false,
      }
    },
    getCmsFeedSuccess: (state, action: PayloadAction<GetCmsFeedSuccessProps>) => {
      state.cmsFeed = {
        loading: false,
        processing: false,
        data: action.payload.content || {},
        error: false,
      }
    },
    getCmsFeedFailure: (state, action: PayloadAction<GetCmsFeedFailureProps>) => {
      const { contentId } = action.payload
      const { data } = state.cmsFeed || initialState.cmsFeed
      state.cmsFeed = {
        loading: false,
        processing: false,
        data,
        error: !data[contentId],
      }
    },
    getServiceGroups: (state) => {
      state.serviceGroups = {
        ...(state.serviceGroups || initialState.serviceGroups),
        loading: true,
      }
    },
    getServiceGroupsSuccess: (state, action: PayloadAction<GetServiceGroupsSuccessProps>) => {
      state.serviceGroups.loading = false
      state.serviceGroups.data = action.payload.serviceGroups || []
    },
    getServiceGroupsFailure: (state) => {
      state.serviceGroups.loading = false
    },
    getOneServiceGroup: (state, action: PayloadAction<GetOneServiceGroupParams>) => {
      state.serviceGroups = {
        ...state.serviceGroups,
        byId: {
          ...state.serviceGroups.byId,
          [action.payload.id]: {
            data: null,
            error: null,
            loading: true,
          },
        },
      }
    },
    getOneServiceGroupSuccess: (state, action: PayloadAction<ServiceGroupV2>) => {
      state.serviceGroups = {
        ...state.serviceGroups,
        byId: {
          ...state.serviceGroups.byId,
          [action.payload.id]: {
            data: action.payload,
            error: null,
            loading: false,
          },
        },
      }
    },
    getOneServiceGroupFailure: (state, action: PayloadAction<FailurePayload>) => {
      state.serviceGroups = {
        ...state.serviceGroups,
        byId: {
          ...state.serviceGroups.byId,
          [action.payload.id]: {
            data: null,
            error: action.payload.error,
            loading: false,
          },
        },
      }
    },
    getExternalService: (state, action: PayloadAction<GetExternalServiceParams>) => {
      state.externalServices = {
        ...state.externalServices,
        [action.payload.id]: {
          data: null,
          error: null,
          loading: true,
        },
      }
    },
    getExternalServiceSuccess: (state, action: PayloadAction<ExtendedExternalService>) => {
      state.externalServices = {
        ...state.externalServices,
        [action.payload.id]: {
          data: action.payload,
          error: null,
          loading: false,
        },
      }
    },
    getExternalServiceFailure: (state, action: PayloadAction<FailurePayload>) => {
      state.externalServices = {
        ...state.externalServices,
        [action.payload.id]: {
          data: null,
          error: action.payload.error,
          loading: false,
        },
      }
    },
  },
})

export { actions as contentActions, initialState }

export default reducer
