import React from 'react'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons'

import Analytics from 'APP/Services/Analytics'
import { navigationRef as Nav } from 'APP/Nav'

import TodayHeader from 'APP/Components/TodayHeader'
import RecentCarePlans from 'APP/Components/RecentCarePlans'
import UnreadMessages from 'APP/Components/UnreadMessages'
import PromotedContent from 'APP/Components/PromotedContent'
import AppLaunchpad from 'APP/Components/AppLaunchpad'
import Habits from 'APP/Containers/Habits'
import GetCareScreen from 'APP/Containers/GetCareScreen'
import InboxScreen from 'APP/Containers/InboxScreen'
import CarePlansScreen from 'APP/Containers/CarePlansScreen'
import IcbtPortalScreen from 'APP/Containers/IcbtPortalScreen'
import CmsFeedScreen from 'APP/Containers/CmsFeedScreen'
import ChallengesScreen from 'APP/Containers/ChallengesScreen'
import MainAction from 'APP/Components/MainAction'
import QuickActions from 'APP/Components/QuickActions'
import InactiveChatInfoCard from 'APP/Components/InfoCard/InactiveChatInfoCard'
import DiscoverNewContent from 'APP/Components/DiscoverNewContent'
import UpcomingAppointments from 'APP/Components/UpcomingAppointments'
import TakeATour from 'APP/Components/TakeATour'
import { CONTENT_FILTERS } from 'APP/Hooks/ContentLibrary'

import { Colors, Images, Metrics } from 'APP/Themes'

import { RootScreens } from 'APP/Lib/Enums'
import { getServiceGroupIcon } from 'APP/Lib/CtaInfoHelpers'
import Icon from 'APP/Converse/Icon'

/**
 * This is a mapping of the name field for AppTabScreen tabs to allow for easy specific tab navigation.
 * This is fairly brittle since these values can be changed in Contentful at any time, breaking this
 * functionality, so uh, don't do that
 */
export const TabScreens = {
  chat: {
    getCare: 'Get Care - Chat',
    inbox: 'Inbox - Chat',
    carePlans: 'Care Plans - Chat',
  },
  move: {
    workouts: 'Workouts - Move',
    challenges: 'Challenges - Move',
  },
  mind: {
    toolkits: 'Toolkits - Mind',
    workouts: 'Mind Workouts - Mind',
  },
}

// Indicate whether a component should render based on a service filter and the user's eligible services
export function shouldRenderComponent(serviceFilter, eligibleServices) {
  const comparator = ({ service_id }) => eligibleServices?.[service_id] !== undefined

  switch (serviceFilter?.boolean_operator) {
    case 'AND':
      return serviceFilter?.services?.every(comparator) ?? true
    case 'OR':
      return serviceFilter?.services?.some(comparator) ?? true
    default:
      return true
  }
}

// Expose for testing
export function _appComponentMapperFactory(eligibleServices, carePlans, enableMainAction) {
  const componentMapping = {
    TodayHeader,
    UnreadMessages,
    RecentCarePlans,
    PromotedContent,
    AppLaunchpad,
    Habits,
    ChallengesScreen,
    CmsFeedScreen,
    GetCareScreen,
    InboxScreen,
    CarePlansScreen,
    IcbtPortalScreen,
    MainAction,
    QuickActions,
    InactiveChatInfoCard,
    DiscoverNewContent,
    UpcomingAppointments,
    TakeATour,
  }

  return (appComponent) => {
    let canRenderComponent
    const componentName = appComponent?.mobile_component_name
    const isEligible = shouldRenderComponent(appComponent?.service_filter, eligibleServices)

    // TODO: clean up all enableMainAction logic once revamped Today screen is rolled out to all brands
    switch (componentName) {
      case 'RecentCarePlans':
        // Override the service filter if the user has careplans
        canRenderComponent = enableMainAction ? carePlans?.length : isEligible || carePlans?.length
        break
      case 'MainAction':
        canRenderComponent = enableMainAction && isEligible
        break
      case 'QuickActions':
        canRenderComponent = !enableMainAction && isEligible
        break
      default:
        canRenderComponent = isEligible
    }

    return canRenderComponent ? componentMapping[componentName] ?? null : null
  }
}

export function useAppComponentMapper() {
  const eligibleServices = useSelector((state) => state?.patient?.profile?.eligibleServices)
  const carePlans = useSelector((state) => state?.history?.completedCarePlans)
  const { enableMainAction } = useSelector((state) => state.features)

  const appComponentMapper = useMemo(
    () => _appComponentMapperFactory(eligibleServices, carePlans, enableMainAction),
    [eligibleServices, carePlans, enableMainAction]
  )

  return appComponentMapper
}

export function navigateToCms(item, buttonValue, analyticsName, sprig = false) {
  Analytics.trackEvent('button_click', { button_value: buttonValue })

  if (item.audio) {
    Nav.navigate('cmsAudioScreen', {
      id: item.id,
      title: item.title,
      services: item.service_filter?.services,
      showShareButton: true,
    })
  }

  if (item.video) {
    Nav.navigate('cmsVideoScreen', {
      id: item.id,
      title: item.title,
      services: item.service_filter?.services,
      showShareButton: true,
    })
  }

  if (!item.audio && !item.video) {
    const name = `${analyticsName}: ${item.id}`

    Nav.navigate('cmsScreen', {
      id: item.id,
      locale: item.locale,
      analyticsName: name,
      screenUri: item.title,
      services: item.service_filter?.services,
      sprig,
      showShareButton: true,
    })
  }
}

const CONTENT_TYPE_ICON = {
  video: {
    icon: MaterialIcon,
    key: 'ondemand-video',
  },
  article: {
    icon: MaterialIcon,
    key: 'import-contacts',
  },
  audio: {
    icon: MaterialCommunityIcon,
    key: 'headphones',
  },
}

export function getContentIcon(item) {
  let Icon = CONTENT_TYPE_ICON.article.icon
  let key = CONTENT_TYPE_ICON.article.key

  if (item.video) {
    Icon = CONTENT_TYPE_ICON.video.icon
    key = CONTENT_TYPE_ICON.video.key
  }

  if (item.audio) {
    Icon = CONTENT_TYPE_ICON.audio.icon
    key = CONTENT_TYPE_ICON.audio.key
  }

  return (
    <Icon
      testID={`icon-${key}`}
      name={key}
      size={Metrics.icons.tiny}
      color={Colors.accent}
      style={{ marginRight: Metrics.baseMargin * 0.3 }}
    />
  )
}

export const normalizeServiceCategory = (serviceCategory, eligibleServices) => ({
  name: serviceCategory.name,
  title: serviceCategory.title,
  shortSubtitle: serviceCategory.short_subtitle,
  longSubtitle: serviceCategory.long_subtitle,
  iconName: serviceCategory.icon_name,
  tag: serviceCategory.tag,
  showPrivacyPolicy: serviceCategory.show_privacy_policy,
  showEmergencyServices: serviceCategory.show_emergency_services,
  serviceFilter: serviceCategory.service_filter,
  scopeOfPractice: serviceCategory.scope_of_practice,
  placeholderHealthIssueTypeId: serviceCategory.placeholder_health_issue_type_id,
  services: serviceCategory.app_services
    ?.filter((serviceCategory) =>
      shouldRenderComponent(serviceCategory?.service_filter, eligibleServices)
    )
    .map(normalizeService),
})

export const normalizeService = (service) => ({
  name: service.name,
  title: service.title,
  shortSubtitle: service.short_subtitle,
  icon: service.icon,
  tag: service.tag,
  actionType: service.action_type,
  actionValue: service.action_value,
  serviceFilter: service.service_filter,
})

const _normalizeContactInfo = (contactInfo) => {
  if (!contactInfo) {
    return contactInfo
  }
  return {
    id: contactInfo.id,
    contentType: contactInfo.content_type,
    name: contactInfo.name,
    title: contactInfo.title,
    content: contactInfo.content,
    cardText: contactInfo.card_text,
    cardCallToAction: contactInfo.card_call_to_action,
    navigationUrl: contactInfo.navigation_url,
    screenName: contactInfo.screen_name,
    actionType: contactInfo.action_type,
  }
}

export const normalizeServiceV2 = (service) => ({
  id: service.id,
  name: service.name,
  title: service.title,
  subtitle: service.subtitle,
  icon: service.icon,
  appIconName: service.app_icon_name,
  tag: service.tag,
  actionType: service.action_type,
  actionValue: service.action_value,
  navigationUrl: service.navigation_url,
  serviceFilter: service.service_filter,
  contentType: service.content_type,
  providerIcon: service.provider_icon,
  providerTitle: service.provider_title,
  browseTitle: service.browse_title,
  browseType: service.browse_type,
  contactInfo: _normalizeContactInfo(service.contact_info),
  ctaLabel: service.cta_label,
  ...(service.service_groups && {
    serviceGroups: service.service_groups.map(normalizeServiceGroup),
  }),
})

export const normalizeServiceGroup = (serviceGroup) => ({
  id: serviceGroup.id,
  name: serviceGroup.name,
  title: serviceGroup.title,
  subtitle: serviceGroup.subtitle,
  contentType: serviceGroup.content_type,
  tileDescription: serviceGroup.tile_description,
  icon: serviceGroup.icon,
  appIconName: serviceGroup.app_icon_name,
  showEmergencyBanner: serviceGroup.show_emergency_services,
  showPrivacyPolicy: serviceGroup.show_privacy_policy,
  placeholderHealthIssueTypeId: serviceGroup.placeholder_health_issue_type_id,
  services: serviceGroup.services?.map(normalizeServiceV2) || [],
  scopeOfPractice: serviceGroup.scope_of_practice,
  screenIconOverride: serviceGroup.screen_icon_override,
})

export const normalizeServiceGroupSection = (serviceGroupSection) => ({
  name: serviceGroupSection.name,
  title: serviceGroupSection.title,
  serviceGroups: serviceGroupSection.items?.map(normalizeServiceGroup),
})

// Expose for testing
export const _allExternalServicesHaveSameProvider = (services) => {
  return (
    services?.length > 0 &&
    services?.every((service) => {
      const isExternalService = service.contentType === CONTENT_FILTERS.APP_EXTERNAL_SERVICE
      const isSameProvider = service.providerTitle === services[0].providerTitle
      return isExternalService && isSameProvider
    })
  )
}

export function allServicesHaveSameProvider(services) {
  return services?.every((s, _, arr) => s.providerTitle === arr[0].providerTitle)
}

export function someServicesHaveShowContactInfoActionType(services) {
  return services.some((s) => s.actionType === 'show_contact_info')
}

export function allServicesHaveSameContactInfoId(services) {
  return services?.every((s, _, arr) => s?.contactInfo?.id === arr[0]?.contactInfo?.id)
}

export const determineExternalProvider = (services) => {
  return _allExternalServicesHaveSameProvider(services) ? services[0].providerTitle : null
}

export function determineContactInfo(services) {
  if (
    services.length == 0 ||
    !allServicesHaveSameProvider(services) ||
    someServicesHaveShowContactInfoActionType(services) ||
    !allServicesHaveSameContactInfoId(services)
  )
    return null

  return services[0].contactInfo
}

export const getServiceOrServiceGroupTileIcon = (serviceOrServiceGroup, params) => {
  const AppIcon = getServiceGroupIcon(serviceOrServiceGroup?.appIconName)
  const DefaultIcon = Images.serviceGroups.default

  if (AppIcon) {
    return <AppIcon {...params} />
  } else if (serviceOrServiceGroup?.icon?.url) {
    let iconParams = {
      src: serviceOrServiceGroup?.icon?.url,
      style: params?.style,
      color: 'accent',
    }
    // Only want to set `size` if not null, otherwise let Icon use its default `size`
    if (params?.width || params?.height) {
      iconParams['size'] = params?.width || params?.height
    }
    return <Icon variant="svgUrl" {...iconParams} />
  } else {
    return <DefaultIcon {...params} />
  }
}

export function getCmsContentIds(eligibleExternalServices) {
  return (
    eligibleExternalServices?.flatMap((item) => {
      if (item?.attributes?.cmsContentId) {
        return item.attributes.cmsContentId
      } else if (item?.attributes?.cmsContent?.length > 0) {
        return item.attributes.cmsContent.map((content) => content?.cmsContentId)
      }
      return []
    }) ?? []
  )
}

// Indicate whether a component should render based on if the component is an internal or external service, service filter and the user's eligible services
export function shouldRenderService(service, eligibleServices, eligibleExternalServices) {
  if (
    service?.contentType === CONTENT_FILTERS.APP_EXTERNAL_SERVICE ||
    service?.contentType === CONTENT_FILTERS.EMPLOYEE_BENEFIT
  ) {
    const cmsContentIds = getCmsContentIds(eligibleExternalServices)

    return cmsContentIds.includes(service?.id)
  } else {
    return shouldRenderComponent(service?.serviceFilter, eligibleServices)
  }
}

export default {
  RootScreens,
  TabScreens,
  shouldRenderComponent,
  useAppComponentMapper,
  navigateToCms,
  getContentIcon,
  normalizeServiceCategory,
  normalizeService,
  normalizeServiceGroup,
  normalizeServiceGroupSection,
  shouldRenderService,
  determineExternalProvider,
  determineContactInfo,
}
