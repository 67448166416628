import UrlBuilder from 'APP/Services/UrlBuilder'
import { Appearance } from 'react-native-appearance'
import Analytics from 'APP/Services/Analytics'

export const handleNavigationContactInfo = (contactInfo, navigation) => {
  const { id, name, actionType, screenName, navigationUrl } = contactInfo || {}

  const isNavigation = actionType === 'navigation' && screenName && navigationUrl

  if (isNavigation) {
    navigation.navigate(screenName, { navigationUrl })
    return
  }

  const uri = UrlBuilder.cmsContactInfoUrl(id, Appearance.getColorScheme())

  navigation.navigate('cmsScreen', { uri, showShareButton: false })
  Analytics.trackScreen(`contactInfoScreen : ${id} : ${name}`)
}
