import React, { useCallback, useEffect, useRef } from 'react'
import { Platform, Animated, View, TouchableWithoutFeedback } from 'react-native'
import Icon from 'react-native-vector-icons/Ionicons'

import BottomSheet from 'APP/Components/BottomSheet'

import { useOnLayout } from 'APP/Hooks/Layout'

// Styles
import { Styles } from './style'
import { useSafeAreaFrame } from 'react-native-safe-area-context'
import useAutoSelectIntakeAction from 'APP/Hooks/useAutoSelectIntakeAction'
import { useTheme } from 'styled-components/native'
import Typography from 'APP/Converse/Typography'

const IntakeBottomSheet = ({ inline = true, title, style, onOpen, onClose, ...other }) => {
  const theme = useTheme()

  const bottomSheet = useRef()
  const frame = useSafeAreaFrame()
  const [{ height: headerHeight }, onHeaderLayout] = useOnLayout()
  const TOP_SNAP =
    !inline && Platform.OS === 'android'
      ? theme.metrics.baseMargin
      : theme.metrics.statusBarHeight + theme.metrics.baseMargin
  const openButtonAnimatedStyles =
    (bottomSheet.current && {
      transform: [
        {
          rotate: bottomSheet.current.drawerOpenAnimatedValue.interpolate({
            inputRange: [0, 0.45, 0.5, 0.55, 1],
            outputRange: ['0deg', '0deg', '90deg', '180deg', '180deg'],
          }),
        },
      ],
    }) ||
    {}

  const onTogglePress = useCallback(() => {
    if (bottomSheet.current) {
      bottomSheet.current.toggle()
    }
  }, [])

  useEffect(() => {
    if (bottomSheet.current) {
      let bottomSnap = frame.height
      if (inline) bottomSnap -= headerHeight
      bottomSheet.current.updateSnapPoints([TOP_SNAP, bottomSnap])

      if (!inline) setTimeout(bottomSheet.current.open, 0)
    }
    // intentionally omit frame from the deps as on Android it will change if keyboard is opened
  }, [headerHeight, inline])

  const onSnap = useCallback(
    (index) => {
      if (index === 0 && typeof onOpen === 'function') {
        onOpen()
      }
      if (index === 1 && typeof onClose === 'function') {
        onClose()
      }
    },
    [onOpen, onClose]
  )

  const openSheet = () => {
    if (bottomSheet.current) {
      setTimeout(() => {
        // * Add small delay before calling open() to ensure component is fully mounted/ready. This prevents race conditions where the bottomSheet ref exists but the component hasn't completed its initialization. This is a temporary fix since we plan to not use this component for Intake in the future.
        bottomSheet?.current?.open()
      }, 200)
    }
  }

  useAutoSelectIntakeAction(title, {
    'Select a timeslot': openSheet,
  })

  return (
    <>
      {/* Push inline layout by the size of the header */}
      {inline && <View style={{ width: frame.width, height: headerHeight || 0 }} />}
      <BottomSheet
        ref={bottomSheet}
        style={[style, { maxHeight: frame.height - TOP_SNAP }]}
        onSnap={onSnap}
        HeaderComponent={
          <TouchableWithoutFeedback
            testID="intakeBottomSheetHeader"
            onPress={onTogglePress}
            onLayout={onHeaderLayout}
          >
            <View style={Styles.headerContainer}>
              <View style={Styles.titleContainer}>
                <Typography variant="h4" color="text">
                  {title}
                </Typography>
              </View>
              <Animated.View style={[Styles.headerButton, openButtonAnimatedStyles]}>
                <Icon name="arrow-up" color={theme.colors.text} size={25} />
              </Animated.View>
            </View>
          </TouchableWithoutFeedback>
        }
        {...other}
      />
    </>
  )
}

export default IntakeBottomSheet
