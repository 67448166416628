import { useEffect, useRef } from 'react'

/**
 * Hook that automatically executes an action when a title matches specified trigger phrases
 
 * This hook helps automate UI interactions when specific messages appear,
 * such as automatically opening a time slot selector when prompted.
 * 
 * @param title - The current message title to match against
 * @param actionMap - Map of message titles to their corresponding actions
 **/

export default function useAutoSelectIntakeAction(
  title: string,
  actionMap: Record<string, () => void>
) {
  const lastExecutedTitleRef = useRef<string | null>(null)

  // Store the latest action map to avoid dependency issues

  useEffect(() => {
    // Skip if we've already executed for this title
    if (title === lastExecutedTitleRef.current) {
      return
    }

    if (title in actionMap) {
      const action = actionMap[title]
      action?.()

      lastExecutedTitleRef.current = title
    }
  }, [title, actionMap])
}
