import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from 'APP/Store/CreateStore'
import { selectPreferredLanguage } from 'APP/Redux/PatientRedux'
import { contentActions } from 'APP/Store/Content'
import { selectExternalServiceState, selectOneServiceGroupState } from 'APP/Store/Content/selectors'

const useServiceData = (serviceGroupId?: string, serviceId?: string) => {
  const dispatch = useDispatch()

  const locale = useSelector(selectPreferredLanguage)
  const serviceGroupState = useSelector((state: RootState) =>
    selectOneServiceGroupState(state, serviceGroupId as string)
  )
  const serviceState = useSelector((state: RootState) =>
    selectExternalServiceState(state, serviceId as string)
  )

  const contentState = serviceGroupId ? serviceGroupState : serviceState

  const [serviceGroup, service] = useMemo(() => {
    if (serviceGroupId) {
      return [
        serviceGroupState?.data,
        serviceGroupState?.data?.services?.find((s) => s.id === serviceId),
      ]
    }
    return [serviceState?.data?.serviceGroups?.[0], serviceState?.data]
  }, [serviceGroupId, serviceId, serviceGroupState, serviceState])

  useEffect(() => {
    if (serviceGroupId) {
      dispatch(contentActions.getOneServiceGroup({ id: serviceGroupId, locale }))
      return
    }
    // If serviceGroupId is missing, fetch the service directly
    if (serviceId) {
      dispatch(contentActions.getExternalService({ id: serviceId, include_service_groups: true }))
    }
  }, [serviceGroupId, serviceId, locale, dispatch])

  return {
    serviceGroup,
    service,
    loading: contentState?.loading || false,
    error: contentState?.error || null,
  }
}

export default useServiceData
