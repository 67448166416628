import { Platform } from 'react-native'
import Config from 'APP/Config'
import I18n from 'APP/Services/i18n'
import * as Geolocation from 'expo-location'
import { all, put, call, select, spawn, delay } from 'redux-saga/effects'
import { RESULTS as PermissionState } from 'react-native-permissions'
import DeviceInfo from 'react-native-device-info'
import { PharmacyType } from '@dialogue/coredata'

import { navigationRef as Nav } from 'APP/Nav'
import * as NestedNavHelper from 'APP/Nav/NestedNavHelper'

import Alert from 'APP/Converse/Alert'
// Redux Actions
import { familyActions } from 'APP/Store/Family'
import LoggerActions from 'APP/Redux/LoggerRedux'
import LoginActions from 'APP/Redux/LoginRedux'
import PatientActions from 'APP/Redux/PatientRedux'
import PatientHistoryActions from 'APP/Redux/PatientHistoryRedux'
import DeeplinkActions from 'APP/Redux/DeeplinkRedux'
import AppSessionActions from 'APP/Redux/AppSessionRedux'
import NotificationActions from 'APP/Redux/NotificationRedux'
import { getAuthenticateApi } from 'APP/Store/utils/healthConnect'
// Sagas
import {
  loginState,
  loginWithUsernameAndPassword,
  watchEmailVerificationCheckRequests,
  registerToken,
} from './AuthSagas'
import {
  fetchEpisodes,
  fetchPractitioners,
  fetchAppointments,
  getOrCreateHealthProfileEpisode,
} from './PatientHistorySagas'
import { videoSessionSagas } from 'APP/Store/VideoSession'
import { deeplinkState } from 'APP/Sagas/DeeplinkSaga'
import { registerPushToken } from './StartupSagas'
import { fetchNotificationPreferences } from './NotificationSagas'
import { familySagas } from 'APP/Store/Family'
import * as NavSagas from './NavSagas'
import Toast from 'react-native-toast-message'

// API Services
import authClient from 'APP/Services/AuthClient'
import CoreData from 'APP/Services/CoreData'
import { Waldo, Silkroad } from '@dialogue/services'
import Mattermost from 'APP/Services/Mattermost'
import Scribe from 'APP/Services/Scribe'
import Analytics, { LAUNCH_CATEGORY } from 'APP/Services/Analytics'
import { identifyLD } from 'APP/Services/LaunchDarkly'
import { videoChannelExists, textChannelExists } from 'APP/Services/PushNotificationServices'
import EnrolmentChallengeOperations from 'APP/Services/EnrolmentChallengeOperations'
import sprigClient from 'APP/Services/Sprig'

// Utils
import { buildSMSPreference } from 'APP/Lib/PreferenceHelpers'
import { getShouldProvideProvince } from 'APP/Lib/ProfileHelpers'
import { handleNonRetryableRequestFailure } from 'APP/Lib/SagaRequestFailureHandler'
import {
  checkLocationState,
  checkLocationService,
  requestLocation,
  requestLocationService,
  checkPostNotificationsState,
  requestPostNotificationsPermissions,
} from 'APP/NativeModules/PermissionsService'
import PushNotification from 'react-native-push-notification'
import { isMobile, isAndroid, isWeb } from 'APP/Helpers/checkPlatform'
import { handlePressContactSupport } from 'APP/Helpers/handlePressContactSupport'
import { PublicComputerType, VerifyEmailErrors } from 'APP/Lib/Enums'
import * as Sentry from '@sentry/react-native'
import { clearAllQueryParamsAndHash } from 'APP/Lib/Utilities'
import { contentSelectors, contentSagas } from 'APP/Store/Content'
import { activeMinutesLogin } from './ActiveMinutesSagas'
import { logDdError } from 'APP/Lib/Datadog'
import { pharmaciesActions } from 'APP/Store/Pharmacies'
import Regex from 'APP/Lib/Regex'

// exported to make available for tests
export const fullState = (state) => state

export const cobrandingsState = (state) => state.cobrandings
export const patientState = (state) => state.patient
export const patientHistoryState = (state) => state.history
export const patientProfileState = (state) => state.patient.profile
export const accountLinkingState = (state) => state.accountLinking
export const appState = (state) => state.app
export const featuresState = (state) => state.features
export const userSettingsState = (userId) => (state) =>
  (state.app && state.app.userSettings && state.app.userSettings[userId]) || {}
export const basicProfileState = (state) => {
  return {
    givenName: state.patient.profile.givenName,
    familyName: state.patient.profile.familyName,
    dateOfBirth: state.patient.profile.dateOfBirth,
    residesIn: state.patient.profile.residesIn,
    phoneNumber: state.patient.profile.phoneNumber,
    preferredLanguage: state.patient.profile.preferred_language,
    profileSetupStatus: state.patient.profile.profileSetupStatus,
  }
}

export const isHealthProfileEnabledState = (state) => {
  const features = featuresState(state)
  const useHealthProfile = features?.useHealthProfile ?? false
  const eligibleServices = state?.patient?.profile?.eligibleServices ?? {}

  return useHealthProfile && !!Object.getOwnPropertyDescriptor(eligibleServices, 'health_profile')
}

export const isCommitmentsRequiredState = (state) => {
  const features = featuresState(state)
  const patient = patientState(state)

  const includesRequiredService =
    patient.profile?.eligibleServices &&
    Object.keys(patient.profile?.eligibleServices)?.some(
      (service) => Config.COMMITMENT.requiredServices.indexOf(service) >= 0
    )

  return (
    features?.showCommitmentScreen && patient?.displayCommitmentScreen && includesRequiredService
  )
}

export const isBasicProfileComplete = (profile) => {
  const shouldProvideProvince = getShouldProvideProvince()

  return (
    profile &&
    profile.givenName &&
    profile.familyName &&
    profile.dateOfBirth &&
    (!shouldProvideProvince || profile.residesIn) &&
    profile.phoneNumber &&
    profile.preferredLanguage
  )
}

export const incomingCallEncounters = (state) => {
  const encounters = state.history.encounters
  const encounterIds = Object.keys(encounters)
  let returnArray = []

  encounterIds.forEach((key) => {
    const encounter = encounters[key]
    if (encounter && encounter.status === 'IN_PROGRESS') returnArray.push(encounter)
  })

  return returnArray
}

export const getCurrentPosition = () => {
  return new Promise((resolve, reject) => {
    Geolocation.getCurrentPositionAsync({
      //Balanced and lowest didn't fetch the location ("Location provider is unavailable" error)
      accuracy: Geolocation.Accuracy.High,
    }).then(
      (position) => {
        const { latitude, longitude } = position.coords
        resolve({
          latitude,
          longitude,
        })
      },
      (error) => {
        let errorCode
        try {
          errorCode = Object.keys(error)
            .filter((k) => {
              return k !== 'code' && error[k] === error.code
            })
            .pop()
        } catch (e) {
          errorCode = 'UNDEFINED'
        }

        const message = (error && error.message) || 'UNDEFINED'

        reject({
          error: errorCode,
          message,
        })
      }
    )
  })
}

export function* requestEpisodes() {
  try {
    yield call(fetchEpisodes)
    yield put(PatientHistoryActions.fetchChannelsRequest(true))
    yield put(PatientHistoryActions.cleanChannels())
    yield spawn(updateLocation)
  } catch (e) {
    yield put(PatientActions.patientProfileFetchFailure(e))
  }
}

export function* requestMessagingCredentials(retryCount = 0) {
  const state = yield select(fullState)
  const mattermost = Mattermost.create(state.login.accessToken)
  try {
    const response = yield call(mattermost.getMe)
    yield put(LoginActions.setMessagingCredentials(response))
    yield put(PatientHistoryActions.mattermostStart())
  } catch (e) {
    if (retryCount < 10) {
      yield delay(1500)
      yield call(requestMessagingCredentials, ++retryCount)
    } else {
      const tokenStatus = !!state.login.accessToken
      const error = new Error(
        `Failed requesting messaging credentials: ${e?.message}. Token status: ${
          tokenStatus ? 'truthy' : 'falsy'
        }`
      )
      yield put(AppSessionActions.setSystemError(error.message, true))
      yield put(LoginActions.logout(error.message))

      yield call(Nav.reset, { index: 0, routes: [{ name: 'welcome' }] })
      Toast.show({
        text1: I18n.t('WelcomeScreen.errorTitle'),
        text2: I18n.t('WelcomeScreen.errorSubtitle'),
      })
      throw error
    }
  }
}

// this block around location permission is messy and spread out accross 2 sagas
// It's a cause of a P2 issue https://dialoguemd.atlassian.net/browse/DIA-75867
// To unblock members it has been modified to skip the check alltogether if we encounter any error
export function* requestLocationPermission({ skip }) {
  try {
    if (!skip) {
      const result = yield call(requestLocation)
      if (Platform.OS === 'android' && (result === 'denied' || result === 'blocked')) {
        yield call(navigateWithLocationPermission, false)
        return false
      } else {
        yield spawn(updateLocation)
      }
    }
    yield call(navigateWithLocationPermission)
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        onboardingStep: 'location-request',
      },
    })
    logDdError(error.message, error.stack, 'CUSTOM', {
      extra: {
        note: 'Error requesting location permission',
      },
    })
    // skip any remaining location checks for Android and advance with the rest of onboarding
    yield call(navigateWithLocationPermission, false)
  }
}

export function* redirectToLocationService({ skip }) {
  if (Platform.OS === 'android') {
    if (!skip) {
      yield requestLocationService()
      yield spawn(updateLocation)
    }
    yield call(navigateWithLocationPermission, !skip)
  } else {
    yield call(navigateWithLocationPermission)
  }
}

export function* updateLocationAuthorized() {
  let updated = false
  try {
    const data = yield call(getCurrentPosition)
    yield put(LoggerActions.log(`Current location: ${JSON.stringify(data)}`))

    const updatedLocation = yield call(refreshWaldoLocation, data)
    if (updatedLocation) {
      const province =
        updatedLocation.administrativeAreaLevel1 ||
        (updatedLocation.Attributes && updatedLocation.Attributes.administrativeAreaLevel1)

      //ATTN: Keep both levels to extract provinces, response wrapped in certain cases
      yield put(
        PatientActions.patientUpdateLocationSuccess({
          longitude: data && data.longitude,
          latitude: data && data.latitude,
          province,
        })
      )
      yield put(LoggerActions.log(`Location updated to ${province}`))
    } else {
      yield put(LoggerActions.log(`Update Location error: invalid geolocation`))
      yield put(PatientActions.patientUpdateLocationFailure('invalid geolocation'))
    }
    updated = true
  } catch (error) {
    yield put(LoggerActions.log(`Update Location error: ${JSON.stringify(error)}`))
  }
  return updated
}

export function* updateLocation() {
  yield put(LoggerActions.log(`Updating location`))
  const locationPermission = yield call(checkLocationState)

  let shouldUpdateLocation
  if (Platform.OS === 'android') {
    const systemLocation = yield call(checkLocationService)
    shouldUpdateLocation = locationPermission === 'granted' && systemLocation
  } else {
    shouldUpdateLocation = locationPermission === 'granted'
  }

  const locationUpdated = shouldUpdateLocation ? yield call(updateLocationAuthorized) : false

  if (!locationUpdated) {
    //ATTN: updatedLocation is updated in case of error ^^
    let msg = 'not shared'
    try {
      yield call(refreshWaldoLocation)
    } catch (error) {
      msg = error
      yield put(LoggerActions.log(`Location not shared error: ${error}`))
    }
    yield put(PatientActions.patientUpdateLocationFailure(msg))
  }
}

export function* createWaldo() {
  const login = yield select(loginState)
  try {
    return Waldo.create(login.accessToken, Config.WALDO_ENDPOINT)
  } catch (error) {
    yield put(LoggerActions.log(`Error occured while creating Waldo ${error}`))
  }
}

export function* refreshWaldoLocation(data) {
  const waldo = yield call(createWaldo)
  const patient = yield select(patientState)
  if (waldo) {
    if (data !== undefined) {
      const response = yield call(waldo.setUserLocation, patient.profile.id, data)
      yield put(LoggerActions.log(`Update waldo`))
      return response
    } else {
      const response = yield call(waldo.setUserLocationNotShared, patient.profile.id)
      yield put(LoggerActions.log(`Update waldo not shared`))
      return response
    }
  }
  return false
}

export function* fetchPatientProfile() {
  const { accessToken } = yield select(loginState)
  const coredataClient = CoreData.create(accessToken)
  try {
    // attempts to get the patient
    const [response, encountersResponse] = yield all([
      call(coredataClient.getPatient),
      call(coredataClient.getPatientEncounters),
    ])

    const patientProfile = response?.data?.data // TODO: consider API transform utility
    const encounters = encountersResponse?.data?.data // TODO: consider API transform utility
    if (encounters) {
      yield put(PatientHistoryActions.fetchEncountersSuccess(encounters))
    }
    if (patientProfile) {
      yield put(PatientActions.patientProfileFetchSuccess(patientProfile))
      if (patientProfile.id) {
        // We send an hashed version of the user_id to sprig
        sprigClient.setUserIdentifier(patientProfile.id)
      }
    }
  } catch (e) {
    const tokenStatus = !!accessToken
    const error = new Error(
      `Failed fetching patient: ${e?.message}. Token status: ${tokenStatus ? 'truthy' : 'falsy'}`
    )
    yield put(PatientActions.patientProfileFetchFailure(error.message))
    yield put(AppSessionActions.setSystemError(error.message, true))
    yield put(LoginActions.logout(error.message))

    yield call(Nav.reset, { index: 0, routes: [{ name: 'welcome' }] })
    Toast.show({
      text1: I18n.t('WelcomeScreen.errorTitle'),
      text2: I18n.t('WelcomeScreen.errorSubtitle'),
    })
    throw error
  }
}

export function* fetchMedicalIdUrl() {
  const login = yield select(loginState)
  const coredataClient = CoreData.create(login.accessToken)
  const medicalIDResponse = yield call(coredataClient.getPatientMedicalIdURL)
  if (medicalIDResponse.ok) {
    yield put(PatientActions.patientProfileMedicalIdFetchSuccess(medicalIDResponse.data.data.url))
  } else {
    yield put(PatientActions.patientProfileMedicalIdFetchFailure(medicalIDResponse))
  }
}

export function* patientProfileGiveConsent() {
  const login = yield select(loginState)
  const coredataClient = CoreData.create(login.accessToken)
  const response = yield call(coredataClient.updatePatient, { hasConsented: true })
  if (response.ok) {
    const patientProfile = response.data.data // TODO: consider API transform utility
    yield put(PatientActions.patientProfileGiveConsentSuccess(patientProfile))
    yield delay(500) // Delay to allow the consent screen transition to complete
    yield call(navigateWithConsent)
  } else {
    yield put(PatientActions.patientProfileGiveConsentFailure(response))
  }
}

export function* startupNavigation() {
  yield put(LoginActions.setIsLoggingIn(false))
  yield put(LoggerActions.log(`startupNavigation`))
  const { customClaims } = yield select(loginState)
  const ssoStatus = customClaims?.sso
  const { error: accountLinkingError } = yield select(accountLinkingState)
  const shouldLinkAccounts = ['ask-link', 'force-link'].indexOf(ssoStatus) >= 0

  // Send the user to the account linking flow when:
  // - the user refreshes the app but has previously failed to link accounts
  // - the user is entering the app using an sso token that is not linked to a dialogue account
  if (accountLinkingError || shouldLinkAccounts) {
    yield call(Nav.navigate, 'accountLinking')
    return
  }

  yield call(navigateWithEmailVerificationCheck)
}

export function* navigateWithEmailVerificationCheck() {
  const { accessToken } = yield select(loginState)

  yield call(fetchUserInfo, accessToken)
  let patient = yield select(patientState)
  const emailVerified = patient.userInfo?.email_verified ?? false

  if (!emailVerified) {
    yield spawn(Analytics.trackLoadingTime, {
      action: LAUNCH_CATEGORY.EXIT,
      label: 'verify email',
    })
    yield call(Nav.reset, { index: 0, routes: [{ name: 'verifyEmail' }] })
    yield spawn(watchEmailVerificationCheckRequests)
  } else {
    yield call(fetchPatientProfile)
    yield spawn(Analytics.trackLoadingTime, {
      action: 'patient profile fetched',
    })
    patient = yield select(patientState)

    yield call(Analytics.identify, patient.profile.id)
    yield call(identifyLD, patient.profile.id)

    yield spawn(Analytics.trackLoadingTime, {
      action: 'email verification checked',
    })

    yield put(videoSessionSagas.Actions.connectToOpentok())
    yield spawn(Analytics.trackLoadingTime, {
      action: 'opentok connected',
    })

    yield all([call(fetchScribeV2User), call(requestMessagingCredentials), call(registerToken)])
    // Get missing profile data
    // done in a sequence as fetchProfileData relies on the result of fetchScribeV2User
    // to retrieve patient.profile.contentTags
    yield call(fetchProfileData)

    // Ensure to fetch user's episodes first before fetching their appointments
    yield spawn(fetchAppointments)

    // This depends on the fetchProfileData to have retrieved the service features
    yield spawn(activeMinutesLogin)

    yield spawn(Analytics.trackLoadingTime, {
      action: 'Scribe, practitioners and location updated',
    })

    yield call(navigateWithEmailVerified)
  }
}

export function* fetchProfileData() {
  const patient = yield select(patientState)
  const features = yield select(featuresState)
  yield all([
    call(fetchScribeV2UserEligibilities),
    call(requestEpisodes),
    spawn(familySagas.fetchFamily),
    spawn(fetchPractitioners),
    spawn(updateLocation),
    patient.profile.idCard && spawn(fetchMedicalIdUrl),
    spawn(contentSagas.fetchLayout, { contentTags: patient.profile.contentTags }),
    call(contentSagas.fetchServiceFeatures),
    call(fetchNotificationPreferences),
    features?.useBenefitsNavigation && spawn(contentSagas.fetchServiceGroups),
  ])
}

export function* navigateWithEmailVerified() {
  const patient = yield select(patientState)
  const basicProfile = yield select(basicProfileState)

  yield put(familyActions.fetchFamilyRequest())

  // Consent check
  if (!patient.profile.hasConsented) {
    const flow = !isBasicProfileComplete(basicProfile) ? 'signupFlow' : 'startupFlow'
    yield spawn(Analytics.trackLoadingTime, {
      action: LAUNCH_CATEGORY.EXIT,
      label: 'complete consent',
    })
    yield call(Nav.navigate, 'consent', { flow })
  } else {
    yield spawn(Analytics.trackLoadingTime, {
      action: 'consent complete',
    })
    yield call(navigateWithConsent)
  }
}

// Basic profile check
export function* navigateWithConsent() {
  const basicProfile = yield select(basicProfileState)
  // profileSetupStatus is set in CoreData when profile information is prefilled
  // before the user signs up and needs to be confirmed during onboarding
  if (!isBasicProfileComplete(basicProfile) || basicProfile.profileSetupStatus === 'prefilled') {
    yield spawn(Analytics.trackLoadingTime, {
      action: LAUNCH_CATEGORY.EXIT,
      label: 'complete profile',
    })
    yield call(Nav.reset, { index: 0, routes: [{ name: 'newProfile' }] })
  } else {
    yield spawn(Analytics.trackLoadingTime, {
      action: 'profile complete',
    })
    yield call(navigateWithBasicProfile)
  }
}

// Attempt to defer enrol during onboarding (first time sign up only)
// Else, geolocation check and request
export function* navigateWithBasicProfile() {
  const patient = yield select(patientState)
  const hasEligibilities = patient.profile?.eligibilityIntervals?.length > 0
  // minimum onboarding done (profile), we can accept authenticated deeplinks now
  yield put(DeeplinkActions.setReadyToProcess(true))

  if (patient.isOnboarding && !hasEligibilities) {
    yield call(Nav.navigate, 'loading', { hideLogo: true })
    yield call(unchallengedInvitationSearch)
  } else {
    yield call(navigateToHealthProfile)
  }
}

export function* navigateToHealthProfile() {
  const isHealthProfileEnabled = yield select(isHealthProfileEnabledState)
  const patient = yield select(patientState)

  if (patient.isOnboarding && isHealthProfileEnabled) {
    const episode = yield call(getOrCreateHealthProfileEpisode, { ctaId: 'onboarding' })
    yield call(Nav.reset, {
      index: 0,
      routes: [
        {
          name: 'healthProfileConversation',
          params: { channelId: episode.id, isOnboarding: true },
        },
      ],
    })
    return
  }
  yield call(navigateToCommitmentScreen)
}

export function* navigateToGeolocationCheck() {
  try {
    const serviceFeatures = yield select(contentSelectors.selectServiceFeatures)
    const geolocationIsNeeded = serviceFeatures.geolocation
    const locationPermission = yield call(checkLocationState)
    const locationPermissionsDenied = locationPermission === 'denied'
    const shouldRequestLocation = geolocationIsNeeded && locationPermissionsDenied

    if (shouldRequestLocation) {
      yield spawn(Analytics.trackLoadingTime, {
        action: LAUNCH_CATEGORY.EXIT,
        label: 'request geolocation',
      })
      yield call(Nav.reset, { index: 0, routes: [{ name: 'newGeolocation' }] })
    } else {
      yield spawn(Analytics.trackLoadingTime, {
        action: !locationPermissionsDenied ? 'geolocation granted' : 'geolocation skipped',
      })

      if (Platform.OS === 'android') {
        // If location permission screen is not prompted, do not display location service screen
        yield call(navigateWithLocationPermission, false)
      } else {
        yield call(navigateWithLocationPermission)
      }
    }
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        onboardingStep: 'location-check',
      },
    })
  }
}

export function* navigateToCommitmentScreen() {
  const patient = yield select(patientState)

  const isCommitmentsRequired = yield select(isCommitmentsRequiredState)
  const isHealthProfileEnabled = yield select(isHealthProfileEnabledState)
  if (isHealthProfileEnabled && !patient?.healthProfileCompleted && patient.isOnboarding) {
    yield call(navigateToHealthProfile)
    return
  }

  if (isCommitmentsRequired) {
    yield call(Nav.navigate, 'commitment')
  } else {
    yield call(navigateToGeolocationCheck)
    yield put(PatientActions.setDisplayCommitmentScreen(false))
  }
}

export function* navigateWithLocationPermission(enableCheck = true) {
  try {
    if (Nav.getCurrentRoute()?.name === 'updateGeolocation') {
      yield call(NestedNavHelper.pop)
    } else {
      //Check location service - skip location service screen if permission was skipped
      let locationServiceEnabled = true
      if (Platform.OS === 'android' && enableCheck) {
        locationServiceEnabled = yield call(checkLocationService)
        yield put(LoggerActions.log(`Location service enabled: ${locationServiceEnabled}`))
      }

      if (locationServiceEnabled) {
        const doesVideoChannelExist = yield call(videoChannelExists)
        const doesTextChannelExist = yield call(textChannelExists)
        const androidChannelsExist = isAndroid() && doesVideoChannelExist && doesTextChannelExist
        const pushPermissionState = yield call(checkPostNotificationsState)

        /**
          On Android we don't want to spam users with the permission request page, so if the channels
          exist (they're created when navigating to the permission page) we skip the permission page,
          regardless of permission state. This is necessary because Android won't return
          PermissionState.BLOCKED like iOS when just checking the state, only when requesting
          permissions.
        */

        if (
          !androidChannelsExist && // Check if channels exist because Android won't return PermissionState.BLOCKED like iOS when just checking the state, only when requesting permissions
          pushPermissionState !== PermissionState.GRANTED &&
          pushPermissionState !== PermissionState.BLOCKED // Don't spam the user with the permission page (only returned for iOS)
        ) {
          yield spawn(Analytics.trackLoadingTime, {
            action: LAUNCH_CATEGORY.EXIT,
            label: 'push notification permission',
          })
          yield call(Nav.reset, { index: 0, routes: [{ name: 'pushNotificationPermission' }] })
        } else {
          // On Android this doesn't actually request permissions but calls getToken
          // which on success envokes onRegistration "forcing" a registration event
          PushNotification.requestPermissions()

          // When the emulator is on, the permission state is unavailable by default
          // and won't return the current permission state
          const isAndroidEmulatorOn =
            DeviceInfo.isEmulatorSync() && pushPermissionState === PermissionState.UNAVAILABLE

          const features = yield select(featuresState)
          const appointmentRemindersResult =
            pushPermissionState === PermissionState.GRANTED || isAndroidEmulatorOn
          const isEnabled = pushPermissionState === PermissionState.GRANTED || isAndroidEmulatorOn

          const sms = buildSMSPreference(
            features?.newNotificationCenter,
            appointmentRemindersResult,
            isEnabled
          )
          yield put(NotificationActions.updateNotificationPreference(sms))

          yield call(navigateToPublicComputer)
        }
      } else {
        yield spawn(Analytics.trackLoadingTime, {
          action: LAUNCH_CATEGORY.EXIT,
          label: 'request system geolocation',
        })
        yield call(Nav.reset, { index: 0, routes: [{ name: 'geolocationService' }] })
      }
    }
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        onboardingStep: 'location-request-processed',
      },
    })
  }
}

export function* requestPostNotifications() {
  yield call(requestPostNotificationsPermissions)

  const result = yield call(checkPostNotificationsState)

  if (isAndroid()) {
    const features = yield select(featuresState)

    // When the emulator is on, the permission state is unavailable by default
    // and won't return the current permission state
    const isAndroidEmulatorOn =
      DeviceInfo.isEmulatorSync() && result === PermissionState.UNAVAILABLE

    const appointmentReminders = result === PermissionState.GRANTED || isAndroidEmulatorOn
    const isEnabled = result === PermissionState.GRANTED || isAndroidEmulatorOn

    const sms = buildSMSPreference(features?.newNotificationCenter, appointmentReminders, isEnabled)

    yield put(NotificationActions.updateNotificationPreference(sms))

    if (result === PermissionState.GRANTED) {
      // On Android, this doesn't actually request permissions but calls getToken
      // which on success envokes onRegistration "forcing" a registration event
      PushNotification.requestPermissions()
    }
  }

  yield call(navigateToPublicComputer)
}

// Show public computer page For web only
export function* navigateToPublicComputer() {
  const { deviceId, publicComputer } = yield select(loginState)

  if (isMobile() || publicComputer !== PublicComputerType.NO_ANSWER) {
    yield call(navigateWithPermission)
  } else {
    const { userInfo } = yield select(patientState)
    const devices = userInfo?.user_metadata?.devices
    const foundDevice = devices?.find((device) => device.device_id === deviceId)

    if (foundDevice) {
      //The user previously selected "dont ask me again on this computer" on this device,
      // update the store with the saved value
      yield put(
        LoginActions.submitPublicComputer({
          publicComputer: foundDevice.is_public
            ? PublicComputerType.ANSWERED_YES
            : PublicComputerType.ANSWERED_NO,
        })
      )
      yield call(navigateWithPermission)
    } else {
      // If the user is logging in and didn't say to not ask again on this device,
      // we show the PublicComputer screen again
      yield call(Nav.reset, { index: 0, routes: [{ name: 'publicComputer' }] })
    }
  }
}

// Org enforced MFA check and request
export function* navigateWithPermission() {
  const patient = yield select(patientState)

  const isMfaRequired = patient?.profile?.mfaRequired ?? false
  const isMfaEnabled = patient?.userInfo?.user_metadata?.mfa_enabled ?? false

  // User is required to have MFA enabled but it is not yet enabled
  if (isMfaRequired && !isMfaEnabled) {
    yield spawn(Analytics.trackLoadingTime, {
      action: LAUNCH_CATEGORY.EXIT,
      label: 'Mandatory MFA enforced',
    })
    yield call(Nav.reset, { index: 0, routes: [{ name: 'mandatoryMFAScreen' }] })
  } else {
    yield spawn(Analytics.trackLoadingTime, {
      action: 'Mandatory MFA check passed',
    })
    yield call(navigateWithMandatoryMfa)
  }
}

export function* navigateWithMandatoryMfa() {
  yield call(NavSagas.syncReset, { index: 0, routes: [{ name: 'tabbar' }] })
  // Onboarding is done, we can follow any deeplinks now
  yield put(PatientActions.setIsOnboarding(false))
  yield put(DeeplinkActions.setReadyToProcess(true))
  // Wait till the end of startup to cleanup the URL
  clearAllQueryParamsAndHash()

  // SSO deeplink secondary action processing
  const deeplink = yield select(deeplinkState)
  if (deeplink.path) {
    // Multi-step-deeplink: set deferredDeeplinkPath if second_action is present
    if (deeplink.props?.second_action) {
      yield put(DeeplinkActions.setDeeplinkPath(deeplink.props.second_action))
    }
    yield put(DeeplinkActions.processDeferredDeeplink())
    yield spawn(Analytics.trackLoadingTime, {
      action: 'process deferred deeplink',
    })
  }
}

export function* updateBasicProfile(action) {
  const login = yield select(loginState)
  const { props } = yield select(deeplinkState)
  const coredataClient = CoreData.create(login.accessToken)
  const updateResponse = yield call(coredataClient.updatePatient, action.patient)
  if (updateResponse.ok) {
    yield call(requestMessagingCredentials)
    yield spawn(requestEpisodes)
    yield put(PatientActions.patientProfileUpdateSuccess(updateResponse.data.data))
    if (props?.code && Regex.enrolmentCode.test(props?.code)) {
      // call enrolment flow if enrolmentCode exist as we don't want to show claim access screen.
      yield call(enrolWithEnrolmentCodeDeeplink, props?.code)
      yield put(DeeplinkActions.processDeferredDeeplinkSuccess())
    } else {
      yield call(navigateWithBasicProfile)
    }
  } else {
    yield put(PatientActions.patientProfileUpdateFailure(updateResponse))
  }
}

export function* getPillwayAccessToken() {
  try {
    const api = yield call(getAuthenticateApi)
    const patient = yield select(patientState)
    const authenticationRequest = {
      vendor: 'pillway',
    }
    const response = yield call(
      [api, api.userAuthenticationV1EpharmacyAuthenticateUserIdPost],
      patient.profile.id,
      authenticationRequest
    )
    const accessToken = response.data?.data?.token
    yield put(PatientActions.pillwayAuthenticationSuccess(accessToken))
  } catch (error) {
    yield put(PatientActions.pillwayAuthenticationFailure(error))
  }
}

export function* fetchServicesAdditionalData(services) {
  const pharmacyExternalIds = services
    ?.filter((service) => service.type === PharmacyType.epharmacy)
    ?.map((service) => service?.attributes?.epharmacyIdentifier)
    ?.join(',')

  if (pharmacyExternalIds) {
    yield put(pharmaciesActions.getPharmacies({ externalIds: pharmacyExternalIds }))
  }
}

export function* fetchUsersHealthResources() {
  yield put(PatientActions.userFetchHealthResourcesRequest())

  const login = yield select(loginState)
  const patient = yield select(patientState)
  const scribe = Scribe.create(login)

  try {
    const userLocation = patient?.profile?.residesIn
    const data = yield call(scribe.getUsersHealthResources, patient.profile.id, {
      ...(userLocation && { home_province: userLocation }),
    })
    if (data == null || data == undefined) {
      throw new Error('No data returned')
    }
    yield put(PatientActions.userFetchHealthResourcesSuccess(data))
    yield spawn(fetchServicesAdditionalData, data)
  } catch (error) {
    yield put(PatientActions.userFetchHealthResourcesFailure())
  }
}

export function* updateProfile(action) {
  const login = yield select(loginState)
  const coredataClient = CoreData.create(login.accessToken)
  const updateResponse = yield call(coredataClient.updatePatient, action.patient)
  if (updateResponse.ok) {
    yield spawn(fetchUsersHealthResources)
    yield put(PatientActions.patientProfileUpdateSuccess(updateResponse.data.data))
    if (action.type === 'PATIENT_PROFILE_UPDATE_REQUEST') {
      yield call(NestedNavHelper.pop)

      const { pushToken } = yield select(appState)
      if (pushToken) {
        yield call(registerPushToken, { pushToken })
      }
    }
  } else {
    yield put(PatientActions.patientProfileUpdateFailure(updateResponse))
  }
}

export function* updateIdCard(action) {
  const login = yield select(loginState)
  const coredataClient = CoreData.create(login.accessToken)
  const response = yield call(coredataClient.getPatientMedicalIdUploadURL)
  if (response.ok) {
    const uploadResponse = yield call(
      coredataClient.uploadPatientMedicalID,
      response.data.data,
      action.fileObj
    )
    if (uploadResponse.ok) {
      const medicalIDResponse = yield call(coredataClient.getPatientMedicalIdURL)
      // overwrite the local representation of the profile
      if (medicalIDResponse.ok) {
        yield put(PatientActions.patientIdCardUpdateSuccess(medicalIDResponse.data.data.url))
      } else {
        yield put(PatientActions.patientIdCardUpdateFailure(medicalIDResponse))
      }
    } else {
      yield put(PatientActions.patientIdCardUpdateFailure(uploadResponse))
    }
  } else {
    yield put(PatientActions.patientIdCardUpdateFailure(response))
  }
}

export function* fetchScribeV2User() {
  yield put(PatientActions.scribeV2UserFetchRequest())

  const login = yield select(loginState)
  const patient = yield select(patientState)
  const scribe = Scribe.create(login)

  try {
    const userLocation = patient?.profile?.residesIn
    const data = yield call(scribe.getScribeV2User, patient.profile.id, {
      ...(userLocation && { home_province: userLocation }),
    })
    if (data == null || data == undefined) {
      throw new Error('No data returned')
    }
    yield put(PatientActions.scribeV2UserFetchSuccess(data))
    yield put(PatientActions.patientCreditCardFetchSuccess(data.creditCard))
    yield spawn(fetchServicesAdditionalData, data.healthResources)
  } catch (error) {
    yield put(PatientActions.scribeV2UserFetchFailure())
  }
}

export function* fetchScribeV2UserEligibilities() {
  yield put(PatientActions.scribeV2UserEligibilitiesFetchRequest())
  const login = yield select(loginState)
  const patient = yield select(patientState)
  const scribe = Scribe.create(login)

  try {
    const data = yield call(scribe.getScribeV2UserEligibilities, patient.profile.id)
    if (data == null || data == undefined) {
      throw new Error('No data returned')
    }
    yield put(PatientActions.scribeV2UserEligibilitiesFetchSuccess(data))
  } catch (error) {
    yield put(PatientActions.scribeV2UserEligibilitiesFetchFailure())
  }
}

export function* registerCreditCard(action) {
  const login = yield select(loginState)
  const patient = yield select(patientState)
  const scribe = Scribe.create(login)
  const token = action.token
  const chargeId = action.chargeId

  try {
    const scribeData = yield call(scribe.registerCreditCard, patient.profile.id, token)
    yield put(PatientActions.patientCreditCardRegisterSuccess(scribeData))
    if (!chargeId) {
      Alert.alert(
        I18n.t('PaymentScreen.add.success.title'),
        I18n.t('PaymentScreen.add.success.body'),
        [
          {
            text: I18n.t('PaymentScreen.add.success.accept'),
            onPress: () => {
              NestedNavHelper.pop()
            },
          },
        ]
      )
    }
  } catch (error) {
    yield put(PatientActions.patientCreditCardRegisterFailure(error))
    yield call(handleNonRetryableRequestFailure, I18n.t('error.alert.addCreditCardSaga'))
  }
}

export function* chargeCreditCard(action) {
  yield put(PatientActions.patientCreditCardChargeRequest())
  const login = yield select(loginState)
  const scribe = Scribe.create(login)
  const { chargeId } = action.args
  try {
    const res = yield call(scribe.chargeCreditCard, chargeId)
    yield put(PatientActions.patientCreditCardChargeSuccess(res))
    Alert.alert(
      I18n.t('PaymentScreen.alerts.success.title'),
      I18n.t('PaymentScreen.alerts.success.body', {
        amount: res.amount,
        description: res.description,
      }),
      [{ text: I18n.t('PaymentScreen.alerts.success.accept') }]
    )
  } catch (error) {
    // Differentiate between API errors and other exceptions
    yield put(PatientActions.patientCreditCardChargeFailure(error))

    if (error.status === '400') {
      if (error.code === 'user_not_registered_on_stripe') {
        Alert.alert(
          I18n.t('PaymentScreen.alerts.addCard.title'),
          I18n.t('PaymentScreen.alerts.addCard.body'),
          [
            { text: I18n.t('PaymentScreen.alerts.addCard.decline') },
            {
              text: I18n.t('PaymentScreen.alerts.addCard.accept'),
              onPress: () => Nav.navigate('addPayment', { chargeId }),
            },
          ]
        )
      }
      if (error.code === 'charge_not_pending') {
        Alert.alert(
          I18n.t('PaymentScreen.alerts.alreadyPaid.title'),
          I18n.t('PaymentScreen.alerts.alreadyPaid.body'),
          [{ text: I18n.t('PaymentScreen.alerts.alreadyPaid.accept') }]
        )
      }
      if (error.code === 'capturing_stripe_charge_card_error') {
        /* istanbul ignore next */
        Alert.alert(
          I18n.t('PaymentScreen.alerts.failure.title'),
          I18n.t('PaymentScreen.alerts.failure.body'),
          [{ text: I18n.t('PaymentScreen.alerts.failure.accept') }]
        )
      }
    } else {
      Alert.alert(
        I18n.t('PaymentScreen.alerts.failure.title'),
        I18n.t('PaymentScreen.alerts.failure.body'),
        [{ text: I18n.t('PaymentScreen.alerts.failure.accept') }]
      )
    }
  }
}

export function* changeEmail(action) {
  try {
    const login = yield select(loginState)
    const silkroad = new Silkroad(login.accessToken, Config.SILKROAD_DOMAIN)
    const { email, resend } = action
    if (email) {
      yield call(silkroad.changeEmailIntent, { new_email: email, use_deeplink: true })
      yield put(PatientActions.patientEmailChangeSuccess())

      if (resend) {
        yield delay(1) //delay due to alert not mixing well with full screen spinner changes.
        Alert.alert(
          I18n.t('ChangeEmailConfirmationScreen.alert.resent.title'),
          I18n.t('ChangeEmailConfirmationScreen.alert.resent.body'),
          [{ text: I18n.t('ChangeEmailConfirmationScreen.alert.resent.accept') }]
        )
      } else {
        yield call(Nav.navigate, 'changeEmailConfirmation', { email })
      }
    } else {
      yield put(
        PatientActions.patientEmailChangeFailure(I18n.t('Common.error.emailUpdate.invalid'))
      )
    }
  } catch (error) {
    const responseData = error.additionalInfo
    let errorMessage = I18n.t('Common.error.generic')
    if (responseData?.error_code) {
      switch (responseData.error_code) {
        case 'invalid_email':
          errorMessage = I18n.t('Common.error.emailUpdate.invalid')
          break
        case 'email_reuse':
          errorMessage = I18n.t('Common.error.emailUpdate.inUse')
          break
        case 'spam_block':
          errorMessage = I18n.t('ChangeEmailConfirmationScreen.alert.spamBlock.body')
          break
      }
    }

    const isSpamBlock = responseData?.error_code === 'spam_block'
    const title = isSpamBlock
      ? I18n.t('ChangeEmailConfirmationScreen.alert.spamBlock.title')
      : I18n.t('Common.error.emailUpdate.generic')
    let textObject = {
      text: I18n.t('Common.accept'),
    }
    if (isSpamBlock) {
      textObject = {
        text: I18n.t('ChangeEmailConfirmationScreen.alert.spamBlock.accept'),
        onPress: handlePressContactSupport,
      }
    }

    Alert.alert(title, errorMessage, [textObject])

    yield put(PatientActions.patientEmailChangeFailure(errorMessage))
  }
}

export function* enrolWithEnrolmentCode(code) {
  const login = yield select(loginState)
  const patient = yield select(patientState)
  const scribe = Scribe.create(login)

  try {
    return yield call(scribe.enrolWithEnrolmentCode, patient.profile.id, code, Config.BRAND_ID)
  } catch (error) {
    // Swallow the error so that the all call doesn't exit early
    return null
  }
}

export function* enrolWithEnrolmentCodes(
  data,
  options = { throwOnError: false, navigationOptions: {} }
) {
  const { throwOnError, navigationOptions } = options
  const patient = yield select(patientState)

  // If the user has enrolled at least one during onboarding, it should see the commitment screen
  if (patient?.isOnboarding) {
    yield put(PatientActions.setDisplayCommitmentScreen(true))
  }

  const enrolmentCalls = data?.codes?.map?.((code) => call(enrolWithEnrolmentCode, code)) ?? []
  const results = (yield all(enrolmentCalls)).filter((result) => result)

  if (results.length > 0) {
    // Refresh the user and their eligibility intervals if any enrolments were successful
    yield all([call(fetchScribeV2User), call(fetchScribeV2UserEligibilities)])
    yield put(PatientActions.patientEnrolWithEnrolmentCodesSuccess(results))

    const { challengedInvitationQueue } = yield select(patientState)

    if (challengedInvitationQueue?.length > 0) {
      yield put(PatientActions.challengedInvitationQueuePop())
    }

    if (navigationOptions?.replace) {
      yield call(NestedNavHelper.replace, 'claimAccessSuccessScreen', navigationOptions ?? {})
    } else {
      yield call(Nav.navigate, 'claimAccessSuccessScreen', navigationOptions ?? {})
    }

    // Refetch the app layout with the user's new contentTags
    yield call(fetchScribeV2User)
    const patient = yield select(patientState)
    yield spawn(contentSagas.fetchLayout, { contentTags: patient.profile.contentTags })

    // Automatically enable SMS appointment reminders for web users after they successfully enrolled.
    if (isWeb()) {
      const features = yield select(featuresState)
      const sms = buildSMSPreference(features?.newNotificationCenter, true, true)
      yield put(NotificationActions.updateNotificationPreference(sms))
    }

    //hack to login to active-minutes incase new eligibility was found
    yield spawn(activeMinutesLogin)
  } else {
    const error = new Error('No codes were accepted')
    yield put(PatientActions.patientEnrolWithEnrolmentCodesFailure(error))
    if (throwOnError ?? false) {
      throw error
    }
  }
}

export function* enrolWithEnrolmentCodeDeeplink(code) {
  yield call(Nav.navigate, 'loading')
  try {
    yield call(enrolWithEnrolmentCodes, { codes: [code] }, { throwOnError: true })
  } catch (error) {
    yield call(Nav.navigate, 'eligibilityClaimScreen', { code })
  }
}

export function* fetchUserInfo(accessToken) {
  yield put(PatientActions.userInfoFetchRequest())

  try {
    const res = yield call([authClient, authClient.getUserInfo], { accessToken })
    yield put(PatientActions.userInfoFetchSuccess(res))
  } catch (error) {
    logDdError('Error fetching user info', 'App/Sagas/PatientSagas.js', 'CUSTOM', { error })
    yield put(PatientActions.userInfoFetchFailure(error))
  }
}

export function* unchallengedInvitationSearch() {
  const login = yield select(loginState)
  const profile = yield select(basicProfileState)
  const scribe = Scribe.create(login)
  try {
    const data = yield call(
      scribe.unchallengedInvitationSearch,
      profile.givenName,
      profile.familyName,
      profile.dateOfBirth,
      Config.BRAND_ID
    )

    if (data === undefined || data.length == 0) {
      throw new Error('No invitations found')
    } else {
      const codes = data.map((invitations) => invitations.enrolment_code)
      yield call(enrolWithEnrolmentCodes, { codes }, { throwOnError: true })
      yield put(PatientActions.unchallengedInvitationSearchSuccess())
    }
  } catch (error) {
    yield put(PatientActions.unchallengedInvitationSearchFailure())
    yield call(challengedInvitationSearch)
  }
}

export function* challengedInvitationSearch() {
  yield put(PatientActions.challengedInvitationSearchRequest())
  const login = yield select(loginState)
  const profile = yield select(basicProfileState)
  const scribe = Scribe.create(login)

  try {
    const data = yield call(
      scribe.challengedInvitationSearch,
      profile.givenName,
      profile.familyName,
      profile.dateOfBirth,
      Config.BRAND_ID
    )

    // Ensure there is only one challenge per organization
    const deduped =
      data?.reduce?.((result, invitation) => {
        const isOrganizationInResult = result.some(
          (i) => i?.organization?.id === invitation?.organization?.id
        )

        if (!isOrganizationInResult) {
          result.push(invitation)
        }
        return result
      }, []) ?? []

    if (deduped === undefined || deduped.length == 0) {
      throw new Error('No invitations found')
    }

    yield put(PatientActions.challengedInvitationSearchSuccess())
    yield call(Nav.navigate, 'challengedEnrolmentsSearchScreen', { searchResult: deduped })
  } catch (error) {
    yield put(PatientActions.challengedInvitationSearchFailure())
    const patient = yield select(patientState)
    const isOnboarding = patient?.isOnboarding

    // For C+, if user has no invitations AND during onboarding, skip to geolocation screen. Otherwise, show an error.
    // For other brands, show claim access screen.
    if (Config.SUPPORTED_CHALLENGED_ENROLMENT_METHOD == null) {
      if (isOnboarding) {
        yield call(navigateToHealthProfile)
      } else {
        yield call(Nav.navigate, 'infoWarning_noInvitationsFound')
      }
    } else {
      if (isOnboarding) {
        yield call(Nav.reset, { index: 0, routes: [{ name: 'eligibilityClaimScreen' }] })
      } else {
        yield call(Nav.navigate, 'eligibilityClaimScreen')
      }
    }
  }
}

export function* challengedInvitationQueueNext() {
  const { challengedInvitationQueue } = yield select(patientState)
  const next = challengedInvitationQueue && challengedInvitationQueue[0]

  if (next) {
    if (next.id === 'claim_access') {
      yield call(Nav.navigate, 'eligibilityClaimScreen')
    } else if (next.challengeForm?.fields?.length > 0) {
      yield call(NestedNavHelper.replace, 'challengedEnrolmentUniqueId', {
        challengedInvitation: next,
      })
    } else {
      yield call(Nav.navigate, 'invitationEmail')
    }
  } else {
    // Queue is empty.
    // If member is in the middle of onboarding, go to the next step which is geolocation check. Otherwise, return home.
    const patient = yield select(patientState)
    if (patient.isOnboarding) {
      yield call(navigateToGeolocationCheck)
    } else {
      yield call(Nav.reset, { index: 0, routes: [{ name: 'tabbar' }] })
    }
  }
}

export function* challengeInvitation({ challenge, uniqueIdentifiers }) {
  const login = yield select(loginState)
  const scribe = Scribe.create(login)
  try {
    // Transform each individual field
    const transformedFields = uniqueIdentifiers.map((identifier, i) => {
      return EnrolmentChallengeOperations.transform(
        identifier.trim(),
        challenge?.challengeForm?.fields?.[i]?.operation
      )
    })

    // Join the fields
    // For single ID just take the first field (should be the only one) since fieldsOperation is undefined
    const transformedField = challenge?.challengeForm?.fieldsOperation
      ? EnrolmentChallengeOperations.transform(
          transformedFields,
          challenge?.challengeForm?.fieldsOperation
        )
      : transformedFields[0]
    const challengeAttribute = challenge.challengeForm.challengeAttribute

    const attribute = { [challengeAttribute]: transformedField }

    const data = yield call(scribe.challengeInvitation, challenge.id, attribute)
    if (!data?.enrolment_code) {
      throw new Error('No enrolment code in response')
    }

    yield call(enrolWithEnrolmentCodes, { codes: [data?.enrolment_code] }, { throwOnError: true })
    yield put(PatientActions.challengeInvitationSuccess())
  } catch (error) {
    yield put(PatientActions.challengeInvitationFailure(error))
  }
}

export function* resendInvitation(data) {
  const login = yield select(loginState)
  const scribe = Scribe.create(login)
  const email = data?.email
  const brandId = Config.BRAND_ID

  try {
    yield call(scribe.resendInvitation, email, brandId)
    yield put(PatientActions.resendInvitationSuccess())

    yield call(Nav.navigate, 'checkEmail', { email })
  } catch (error) {
    yield put(PatientActions.resendInvitationFailure(error))
  }
}

export function* changeEmailAuthorized(token, email) {
  const { accessToken } = yield select(loginState)
  const silkroad = new Silkroad(accessToken, Config.SILKROAD_DOMAIN)
  try {
    yield call(silkroad.changeEmail, token)
    yield put(DeeplinkActions.processDeferredDeeplinkSuccess())
    yield call(Nav.navigate, 'verifyNewEmailResult', { success: true, email })
  } catch (error) {
    yield put(PatientActions.changeEmailFailure(error))
    yield call(Nav.navigate, 'verifyNewEmailResult', {
      errorType: VerifyEmailErrors.EMAIL_CHANGE_ERROR,
    })
    // Do not log if user enters wrong password
    if (error?.code !== 403) {
      Sentry.captureException(error?.code)
      logDdError(error.message, error.stack)
    }
  }
}

export function* authorizeAndChangeEmail({ password }) {
  const { changeEmailToken } = yield select(patientState)
  const { new_email, old_email, token } = changeEmailToken
  const { accessToken } = yield select(loginState)
  yield put(PatientActions.setChangeEmailToken({ new_email, token, password }))
  if (accessToken) {
    yield call(changeEmailAuthorized, token, new_email)
  } else {
    try {
      yield call(
        loginWithUsernameAndPassword,
        {
          username: old_email,
          password,
        },
        true
      )
      yield call(changeEmailAuthorized, token, new_email)
    } catch (error) {
      yield put(PatientActions.changeEmailFailure(error))
      Sentry.captureException(error?.code)
      logDdError(error.message, error.stack)
      yield put(LoginActions.logout(error.message))
    }
  }
}

export function* runStartupAfterChangeEmail() {
  const {
    changeEmailToken: { new_email, password },
  } = yield select(patientState)
  yield put(PatientActions.changeEmailSuccess(new_email))
  yield call(loginWithUsernameAndPassword, {
    username: new_email,
    password,
  })
}

export function* submitHealthProfileCompleted() {
  const patient = yield select(patientState)
  if (patient.isOnboarding) {
    yield call(navigateToCommitmentScreen)
  } else {
    yield call(Nav.goBack)
  }
}
