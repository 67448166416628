import type { PayloadAction } from '@reduxjs/toolkit'
import { call, put, select } from 'typed-redux-saga'

import {
  normalizeServiceGroup,
  normalizeServiceGroupSection,
  normalizeServiceV2,
} from 'APP/Lib/ContentHelpers'
import {
  getCmsFeed,
  getLayout,
  getServiceFeatures,
  getAppComponents,
  getOneServiceGroup,
  getExternalService,
} from 'APP/Services/CMS'

import {
  selectLayoutState,
  selectServiceFeaturesState,
  selectCmsFeedState,
  selectServiceGroupsState,
} from './selectors'
import { contentActions } from './slice'
import {
  FetchCmsFeedContentProps,
  FetchLayoutProps,
  GetExternalServiceParams,
  GetOneServiceGroupParams,
  ServiceGroupV2,
} from './types'

export function* fetchLayout(payload: FetchLayoutProps) {
  yield* put(contentActions.getLayout())
  try {
    const layout = yield* call(getLayout, payload)
    yield* put(contentActions.getLayoutSuccess({ layout }))
  } catch (error) {
    // layout request failed -> fall back to previous value from the store
    // if no previous layout is available in the store (simple check for top-level id property) use the cmsFallback/layout.json
    yield* put(contentActions.getLayoutFailure())
    const previousLayoutData = yield* select(selectLayoutState)
    if (!previousLayoutData?.id) {
      let fallbackLayoutConfig
      try {
        fallbackLayoutConfig = require('APP/Config/cmsFallback/layout.json')
      } catch (e) {
        fallbackLayoutConfig = {}
      }
      yield* put(contentActions.getLayoutSuccess({ layout: fallbackLayoutConfig }))
    }
  }
}

export function* fetchServiceFeatures() {
  yield* put(contentActions.getServiceFeatures())
  try {
    const serviceFeatures = yield* call(getServiceFeatures)
    yield* put(contentActions.getServiceFeaturesSuccess({ serviceFeatures }))
  } catch (error) {
    // request failed -> fall back to previous value from the store
    // if no previous value is available in the store (simple check for length) use the cmsFallback/serviceFeatures.json
    yield* put(contentActions.getServiceFeaturesFailure())
    const prevServiceFeatures = yield* select(selectServiceFeaturesState)
    if (!prevServiceFeatures?.length) {
      let serviceFeatures
      try {
        serviceFeatures = require('APP/Config/cmsFallback/serviceFeatures.json')
      } catch (e) {
        serviceFeatures = {}
      }
      yield* put(contentActions.getServiceFeaturesSuccess({ serviceFeatures }))
    }
  }
}

export function* fetchCmsFeedContent({ payload }: PayloadAction<FetchCmsFeedContentProps>) {
  const { contentId, locale } = payload
  try {
    const fetchedData = yield* call(getCmsFeed, contentId, locale)
    const { data } = yield* select(selectCmsFeedState)
    const dataForId = data[contentId]
    const clonedData = Object.assign({}, data)

    if (JSON.stringify(fetchedData) !== JSON.stringify(dataForId)) {
      clonedData[contentId] = fetchedData
    }
    yield* put(contentActions.getCmsFeedSuccess({ content: clonedData }))
  } catch (error) {
    yield* put(contentActions.getCmsFeedFailure({ contentId }))
  }
}

export function* fetchServiceGroups() {
  yield* put(contentActions.getServiceGroups())
  try {
    const appComponentList = yield* call(getAppComponents, {
      mobileComponentName: 'GetCareScreen',
    })
    const normalizedServiceGroups = appComponentList?.items[0]?.data?.items?.map(
      normalizeServiceGroupSection
    )
    yield* put(contentActions.getServiceGroupsSuccess({ serviceGroups: normalizedServiceGroups }))
  } catch (error) {
    // serviceGroups request failed -> fall back to previous value from the store
    // if no previous serviceGroups are available in the store (simple check for top-level id property) use the cmsFallback/serviceGroups.json
    yield* put(contentActions.getServiceGroupsFailure())
    const previousServiceGroupsData = yield* select(selectServiceGroupsState)
    if (!previousServiceGroupsData?.length) {
      let fallbackServiceGroups
      try {
        fallbackServiceGroups = require('APP/Config/cmsFallback/serviceGroups.json')
      } catch (e) {
        fallbackServiceGroups = { items: [] }
      }
      const normalizedFallbackServiceGroups = fallbackServiceGroups?.items[0]?.data?.items?.map(
        normalizeServiceGroupSection
      )
      yield* put(
        contentActions.getServiceGroupsSuccess({ serviceGroups: normalizedFallbackServiceGroups })
      )
    }
  }
}

export function* fetchOneServiceGroup({ payload }: PayloadAction<GetOneServiceGroupParams>) {
  try {
    const serviceGroup = yield* call(getOneServiceGroup, payload.id, payload.locale)
    const normalizedServiceGroup: ServiceGroupV2 = normalizeServiceGroup(serviceGroup)
    yield* put(contentActions.getOneServiceGroupSuccess(normalizedServiceGroup))
  } catch (e) {
    yield* put(contentActions.getOneServiceGroupFailure({ id: payload.id, error: e as Error }))
  }
}

export function* fetchExternalService({ payload }: PayloadAction<GetExternalServiceParams>) {
  const { id, ...rest } = payload
  try {
    const service = yield* call(getExternalService, id, rest)
    yield* put(contentActions.getExternalServiceSuccess(normalizeServiceV2(service)))
  } catch (e) {
    yield* put(contentActions.getExternalServiceFailure({ id: payload.id, error: e as Error }))
  }
}
