import React, { useContext, memo } from 'react'
import { Platform } from 'react-native'
import { useNavigation } from '@react-navigation/native'

import { Images } from 'APP/Themes'

import { getSuitableFullscreenMicroAppForPost } from 'APP/Lib/MicroAppHelpers'
import { ChatContext, PostContext } from 'APP/Lib/Context'
import { channelToTimeStampForNewPost } from 'APP/Lib/MessagingHelpers'
import { useDispatch } from 'react-redux'
import PatientHistoryActions from 'APP/Redux/PatientHistoryRedux'
import Analytics from 'APP/Services/Analytics'
import ActionCard from 'APP/Components/FelixChat/ActionCard'
import useAutoSelectIntakeAction from 'APP/Hooks/useAutoSelectIntakeAction'

const MicroApp = () => {
  const { onMessageSend, channelId, userId, conversation } = useContext(ChatContext)
  const { post } = useContext(PostContext)
  const title = post.message

  const navigation = useNavigation()

  const { id, props } = getSuitableFullscreenMicroAppForPost(post)
  const dispatch = useDispatch()

  const onPress = () => {
    Analytics.trackEvent('button_click', {
      button_value: `Open microapp ${id}`,
    })

    navigation.navigate('microAppDrawer', {
      microAppProps: { id, ...microAppProps },
      title,
    })
  }

  const sendAnswer = (reply) => {
    const { answer_flags, payload, display, question_type } = reply
    const timeStamp = channelToTimeStampForNewPost(conversation)

    dispatch(
      PatientHistoryActions.submitAnswerPostRequest(
        channelId,
        userId,
        {
          question_type,
          text: display,
          data: payload,
        },
        timeStamp,
        answer_flags
      )
    )
    dispatch(PatientHistoryActions.updateChannelLastViewed(channelId))
  }

  const microAppProps = {
    appId: id,
    displayMode: Platform.OS === 'android' ? 'fullscreen' : 'drawer',
    post,
    userId: post.user_id,
    messageFormat: 'mattermost',
    sendText: onMessageSend,
    sendAnswer,
    ...props,
  }

  useAutoSelectIntakeAction(title, {
    'Select a timeslot': onPress,
  })

  return (
    <ActionCard title={title} icon={Images.inChatMicroApp[microAppProps.appId]} onPress={onPress} />
  )
}

export default memo(MicroApp)
