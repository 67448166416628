import { create } from 'apisauce'
import Config from 'APP/Config'
import I18n from 'APP/Services/i18n'

const api = create({
  baseURL: Config.CMS.baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 50000, // 20 seconds
})
Config.onConfigKeyChange('CMS', (done) => {
  api.setBaseURL(Config.CMS.baseUrl)
  done()
})
// Parse filters into a single query string
const queryParams = (filters) =>
  Object.entries(filters)
    .reduce((query, [key, value]) => {
      // Implement `repeat` array format (e.g. `?eg=a&eg=b`), because FastAPI
      // doesn't support default axios format (e.g. `?eg[]=a,b`)
      if (Array.isArray(value)) {
        value.forEach((v) => query.append(key, v))
      } else {
        query.append(key, value)
      }
      return query
    }, new URLSearchParams())
    .toString()

export async function getArticles(
  ctaId = 'mentalHealth',
  bySearch = false,
  filters = {},
  locale = I18n.baseLocale
) {
  if (bySearch) {
    const response = await api
      .get(`/document/search/?${queryParams(filters)}`, { locale })
      .then((response) => {
        if (response.ok) {
          return response.data
        } else {
          return {}
        }
      })

    return response
  }

  const slugs = Config.CMS.slugs
  const slugId = slugs && slugs[ctaId]

  const response = await api
    .get(`/document-list/by-slug/${slugId}`, { locale })
    .then((response) => {
      if (response.ok) {
        return response.data
      } else {
        throw new Error(`Error retrieving articles`)
      }
    })

  return response
}

export async function getVideos(filters = {}, locale = I18n.baseLocale) {
  const response = await api
    .get(`/video/search/?${queryParams(filters)}`, { locale })
    .then((response) => {
      if (response.ok) {
        return response.data
      } else {
        return {}
      }
    })

  return response
}

export async function getAudio(filters = {}, locale = I18n.baseLocale) {
  const response = await api
    .get(`/audio/search/?${queryParams(filters)}`, { locale })
    .then((response) => {
      if (response.ok) {
        return response.data
      } else {
        return {}
      }
    })

  return response
}

export async function getHabit(id, locale = I18n.baseLocale) {
  const response = await api.get(`/habit/${id}`, { locale }).then((response) => {
    if (response.ok) {
      return response.data
    } else {
      return {}
    }
  })

  return response
}

export async function getLayout({ contentTags = [], locale = I18n.baseLocale }) {
  const response = await api
    .get(`/app_layout?${queryParams({ tags: contentTags, locale })}`)
    .then((response) => {
      if (response.ok) {
        return response.data
      } else {
        throw {
          status: response.error,
          problem: response.problem,
          originalError: response.originalError,
          data: response.data,
        }
      }
    })

  return response
}

export async function getServiceFeatures() {
  const response = await api.get(`/app_service_features`).then((response) => {
    if (response.ok) {
      return response.data
    } else {
      throw {
        status: response.error,
        problem: response.problem,
        originalError: response.originalError,
        data: response.data,
      }
    }
  })

  return response
}

export async function getCmsFeed(entryId, locale = I18n.baseLocale) {
  const response = await api.get(`/app_content/${entryId}`, { locale }).then((response) => {
    if (response.ok) {
      return response.data
    } else {
      throw {
        status: response.error,
        problem: response.problem,
        originalError: response.originalError,
        data: response.data,
      }
    }
  })

  return response
}

export async function getBio(entryId, locale = I18n.baseLocale, memberId) {
  const response = await api.get(`/profile/${entryId}`, { locale, member_id: memberId })
  if (response.ok && response.data) {
    return response.data
  }

  throw {
    status: response.error,
    problem: response.problem,
    originalError: response.originalError,
    data: response.data,
  }
}

export async function getCmsContent(
  path,
  style = 'light',
  toJson = true,
  locale = I18n.baseLocale
) {
  const response = await api.get(path, { style, to_json: toJson, locale })
  if (response.ok && response.data) {
    return response.data
  }

  throw {
    status: response.error,
    problem: response.problem,
    originalError: response.originalError,
    data: response.data,
  }
}

export async function getAppComponents({
  mobileComponentName,
  locale = I18n.baseLocale,
  brandId = Config.BRAND_ID,
} = {}) {
  let params = { locale, brand_id: brandId }

  // Add mobile_component_name to params, if set
  if (mobileComponentName) {
    params['mobile_component_name'] = mobileComponentName
  }

  const response = await api.get(`/app_components?`, params)
  if (response.ok) {
    return response.data
  } else {
    throw {
      status: response.error,
      problem: response.problem,
      originalError: response.originalError,
      data: response.data,
    }
  }
}

export async function getOneServiceGroup(id, locale = I18n.baseLocale) {
  const response = await api.get(`/app_service_groups/${id}`, { locale })
  if (response.ok) {
    return response.data
  } else {
    throw {
      status: response.error,
      problem: response.problem,
      originalError: response.originalError,
      data: response.data,
    }
  }
}

export async function getExternalService(id, params) {
  const response = await api.get(`/app_external_services/${id}`, params)
  if (response.ok) {
    return response.data
  } else {
    throw {
      status: response.error,
      problem: response.problem,
      originalError: response.originalError,
      data: response.data,
    }
  }
}

export default {
  getArticles,
  getVideos,
  getAudio,
  getHabit,
  getLayout,
  getCmsFeed,
  getBio,
  getCmsContent,
  getAppComponents,
  getOneServiceGroup,
  getExternalService,
}
